import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
import classes from "./SignInModal.module.css"

export const SignInModal = (props) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    
    props.onSignInClick(email)
    // if (!newName.trim()) {
    //   return;
    // }

    // props.editTask(props.id, newName);
    // setNewName("");
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div>
      <div className={classes.backdrop}></div>
      <Form className={classes.modal} onSubmit={handleSubmit}>
        <Form.Group>
          <h2 className={classes.signInHeader}>Log in to continue</h2>
          <Form.Control
            type="text"
            value={email}
            onChange={handleChange}
            placeholder="Enter your email"
          />
        </Form.Group>
        <div className="btn-group">
          <Button type="submit">Continue</Button>
        </div>
      </Form>
    </div>
  );
};
