import React, { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { v4 as uuidv4 } from 'uuid';


import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import { OrganizationFrom } from "./OrganizationFrom";

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const OrganizationView = (props) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiTodoList.scopes.write
    });

    const [organization, setOrganization] = useState("");

    const handleAddOrganization = (name) => {
        const newOrganization = {
            id: uuidv4(),
            owner: account.idTokenClaims?.sub,
            organizationName: name
        };

        execute("POST", protectedResources.apiTodoList.endpointOrganizations + `/PostOrganization`, newOrganization).then((response) => {
            if (response && response.message === "success") {
                setOrganization(newOrganization);
            }
        })
    }

    const handleChangeOrganization = (newName) => {
        const updatedOrganization = {
            owner: account.idTokenClaims?.sub,
            organizationName: newName
        };
        
        execute("PUT", protectedResources.apiTodoList.endpointOrganizations + `/ChangeOrganization`, updatedOrganization).then((response) => {
            setOrganization(newName);
        });
    }

    const onOrganizationNameChange = (organizationName) => {
        setOrganization(organizationName);
    }

    useEffect(() => {
        if (!organization) {
            var owner = account.idTokenClaims?.sub;

            execute("GET", protectedResources.apiTodoList.endpointOrganizations + `/GetOrganization/owner/${owner}`).then((response) => {
                if(response && response.status !== 204)
                {
                    setOrganization(response.organizationName);
                }
            });
        }
    }, [execute, organization])

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    return (
        <div className="data-area-div">
            { organization
                ? <OrganizationFrom submitForm={handleChangeOrganization} name={organization} onChange={onOrganizationNameChange} submitButtonName="Change" />
                : <OrganizationFrom submitForm={handleAddOrganization} name={organization} onChange={onOrganizationNameChange} submitButtonName="Register" />
            }
        </div>
    );
}
